import React from "react";

// import { useTranslation } from "react-i18next";
import { readState } from "@/__main__/app-state.mjs";
import type { Matchlist } from "@/feature-lol-rust-be/pro-match-history-model.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import MatchlistComponent from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
import { FeedSection } from "@/feature-reskin-probuilds-net/Home.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Role() {
  return (
    <Layout>
      <Feed />
    </Layout>
  );
}

function Feed() {
  const {
    parameters: [currentRole],
  } = useRoute();
  const state = useSnapshot(readState);

  const proBuildMatchlist = state.lol.proBuildMatchlist[
    currentRole
  ] as Matchlist;

  return (
    <FeedSection>
      <MatchlistComponent matches={proBuildMatchlist} currRole={currentRole} />
    </FeedSection>
  );
}

export default Role;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
